export function getEnv(name: string): string | undefined {
    if (typeof window !== "undefined" && window._env_ && window._env_[name]) {
      return window._env_[name];
    }
  
    if (import.meta && import.meta.env && import.meta.env[name]) {
      return import.meta.env[name];
    }
  
    return undefined;
  }