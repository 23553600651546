import axios from "axios";
import { api } from "src/api/api";

import { getEnv } from "../utils/Env.ts";

const axiosInstance = axios.create({
  baseURL: getEnv("VITE_BACKEND_HOST") ?? "http://localhost:3000",
});

axiosInstance.interceptors.request.use(async (config) => {
  const accessToken = api.getAccessToken();
  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }
  return config;
});

axiosInstance.interceptors.response.use(
  async (res) => res?.data,
  (err) => {
    return Promise.reject(err);
  }
);

export default axiosInstance;
