// src/theme/index.tsx
import { ReactNode, useMemo } from "react";
import {
  createTheme,
  ThemeProvider as MUIThemeProvider,
} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import { palette } from "./palette";
import { shadows } from "./shadows";
import { overrides } from "./overrides";
import { typography } from "./typography";
import { customShadows } from "./custom-shadows";

// ----------------------------------------------------------------------

interface Props {
  children: ReactNode;
}

export default function ThemeProvider({ children }: Props) {
  const baseTheme = useMemo(
    () =>
      createTheme({
        palette: palette(),
        typography,
        shadows: shadows(),
        customShadows: customShadows(),
        shape: { borderRadius: 8 },
      }),
    []
  );

  const theme = useMemo(
    () =>
      createTheme(baseTheme, {
        components: overrides(baseTheme),
      }),
    [baseTheme]
  );

  return (
    <MUIThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </MUIThemeProvider>
  );
}
