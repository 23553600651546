import { useState } from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Nav from './nav';
import { usePathname } from 'src/routes/hooks';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { icon } from 'src/utils/loadSvg';

export default function DashboardLayout({ children }) {
  const [open, setOpen] = useState(false);
  const pathname = usePathname();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const isShiftPlanPage = pathname.includes('shift-plans/');
  const COLLAPSED_WIDTH = 72;
  const EXPANDED_WIDTH = 280;

  const sidebarWidth = isShiftPlanPage ? COLLAPSED_WIDTH : EXPANDED_WIDTH;

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: 'flex', minHeight: '100vh' }}>
      {!isDesktop && (
        <IconButton
          onClick={handleDrawerOpen}
          sx={{
            position: 'fixed',
            top: 16,
            left: 16,
            zIndex: 999,
            bgcolor: 'background.paper',
            boxShadow: 1,
            width: 40,
            height: 40,
          }}
        >
          {icon('dash-icon', 50, 50)}
        </IconButton>
      )}

      <Nav openNav={open} onCloseNav={handleDrawerClose} />

      <Box
        component="main"
        sx={{
          width: {
            xs: '100%',
            lg: `calc(100% - ${sidebarWidth}px)`
          },
          position: 'relative',
          flexGrow: 1,
          transition: theme => theme.transitions.create(['width', 'margin-left'], {
            duration: theme.transitions.duration.shorter,
          }),
          backgroundColor: 'white',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          overflowX: 'hidden',
        }}
      >
        <Box
          sx={{ 
            flexGrow: 1,
            width: '100%',
            px: isShiftPlanPage ? 0 : 0,
            py: isShiftPlanPage ? 0 : 0,
          }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
}