import { Suspense, ComponentType } from "react";
import Loader from "../../components/Loader.tsx";

// Define base props interface for components
interface BaseComponentProps {
  children?: React.ReactNode;
  [key: string]: unknown;
}

// Helper function to wrap components with Suspense using proper typing
const Loadable = <P extends BaseComponentProps>(
  Component: ComponentType<P>
) => {
  const WrappedComponent = (props: P) => (
    <Suspense fallback={<Loader />}>
      <Component {...props} />
    </Suspense>
  );

  // Preserve the display name for debugging
  WrappedComponent.displayName = `Loadable(${
    Component.displayName || Component.name || "Component"
  })`;

  return WrappedComponent;
};

export default Loadable;
