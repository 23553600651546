import { lazy } from "react";
import Loadable from "./components/Loadable";

// Auth
// -------------------
// -------------------
export const Login = Loadable(
  lazy(() => import("src/pages/authentication/LoginPage"))
);

export const IndexPage = Loadable(
  lazy(() => import("src/pages/dashboard/AppPage"))
);
export const Page404 = Loadable(lazy(() => import("src/pages/NotFoundPage")));

// Shift Plan
// -------------------
// -------------------

export const ShiftPlan = Loadable(
  lazy(() => import("src/pages/shiftPlan/ShiftPlan"))
);

export const ShiftPlanDetail = Loadable(
  lazy(() => import("src/pages/shiftPlan/ShiftPlanDetail"))
);

// Department
// -------------------
// -------------------
export const Departments = Loadable(
  lazy(() => import("src/pages/department/Departments"))
);

export const DepartmentDetail = Loadable(
  lazy(() => import("src/pages/department/DepartmentDetail"))
);

// Unit
// -------------------
// -------------------
// export const UnitDetail = Loadable(
//   lazy(() => import("src/pages/unit/UnitDetail"))
// );

// export const UnitCreate = Loadable(
//   lazy(() => import("src/pages/unit/UnitCreate"))
// );
