import { ReactNode, useState } from "react";

interface Props {
  children: ReactNode;
}

const ErrorBoundary = (props: Props) => {
  const [error, setError] = useState(null);

  function resetError() {
    setError(null);
  }

  if (error) {
    return (
      <div>
        <h1>Something went wrong</h1>
        <button onClick={resetError}>Try again</button>
      </div>
    );
  }

  return props.children;
};

export default ErrorBoundary;
