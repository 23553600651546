import { ShiftRestrictionType } from "./unit";

export enum Role {
  SUPER_ADMIN = "SUPER_ADMIN",
  ORGANIZATION_ADMIN = "ORGANIZATION_ADMIN",
  DEPARTMENT_MANAGER = "DEPARTMENT_MANAGER",
  WORKER = "WORKER",
}

export type UserType = {
  id: number;
  avatarUrl: string;
  firstName: string;
  lastName: string;
  role: string;
  email: string | null;
  organizationId: number;
  reportLabel: string;
};

export type WorkerProfileWithUser = {
  unitId: number;
  workerProfileType: WorkerProfileEnum;
  contractPercentage: number | null;
  shiftRestrictions: ShiftRestrictionType[];
  canWorkAlone: boolean | null;
  canWorkAtWeekend: boolean | null;
  newUser?: {
    firstName: string;
    lastName: string;
    email: string | null;
    reportLabel: string;
    password: string | null;
  };
};

export interface CreateWorkerProfile
  extends Omit<WorkerProfileWithUser, "newUser"> {
  userId: number;
}

export type CreateShiftPlanWorkerProfileType = {
  userId?: number;
  spUnitId?: number;
  workerProfileType: WorkerProfileEnum;
  contractPercentage: number | null;
  shiftRestrictions: ShiftRestrictionType[];
  canWorkAlone: boolean | null;
  canWorkAtWeekend: boolean | null;
};

export type UpdateWorkerProfileType = {
  contractPercentage: number | null;
  shiftRestrictions: ShiftRestrictionType[];
  canWorkAlone: boolean | null;
  canWorkAtWeekend: boolean | null;
};

export type UpdateSpWorkerProfile = {
  userId?: number;
  spUnitId?: number;
  contractPercentage: number | null;
  shiftRestrictions: ShiftRestrictionType[];
  canWorkAlone: boolean | null;
  canWorkAtWeekend: boolean | null;
};

export type WorkerProfileType = {
  id: number;
  userId?: number;
  user: UserType;
  unitId: number;
  spUnitId?: number;
  departmentId: number;
  workerProfileType: WorkerProfileEnum;
  contractPercentage: number | null;
  shiftRestrictions: ShiftRestrictionType[];
  canWorkAlone: boolean | null;
  canWorkAtWeekend: boolean | null;
  mentorId: number | null;
  created?: string;
  updated?: string;
};

export type UnitUsers = {
  [unitId: number]: WorkerProfileType[];
};

export enum WorkerProfileEnum {
  PRIMARY = "PRIMARY",
  PRIMARY_SUBSTITUTION = "PRIMARY_SUBSTITUTION",
  SECONDARY_SUBSTITUTION = "SECONDARY_SUBSTITUTION",
  EXTERNAL = "EXTERNAL",
}
