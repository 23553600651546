import { LoggedInUserType, LoginUserType } from "src/types/auth";
import api from "../lib/lib";
import { UserType } from "src/types/user";

export const authApi = {
  login: (data: LoginUserType): Promise<LoggedInUserType> => {
    return api.post("/auth/login", data);
  },
  me: (): Promise<UserType> => {
    return api.get("/auth/me");
  },
};
