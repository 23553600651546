import React, { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import ReactDOM from "react-dom/client";

import "./index.scss";

import App from "./app.jsx";
import ThemeProvider from "./theme/index.jsx";
import ErrorBoundary from "./components/ErrorBoundary.tsx";
import { AuthProvider } from "./providers/AuthProvider.tsx";
import { SnackbarProvider } from "notistack";
import Loader from "./components/Loader.tsx";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

const queryClient = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById("root")!);
/*
const onRenderCallback = (
  id: string, // the "id" prop of the Profiler tree that has just committed
  phase: "mount" | "update", // either "mount" (if the tree just mounted) or "update" (if it re-rendered)
  actualDuration: number, // time spent rendering the committed update
  baseDuration: number, // estimated time to render the entire subtree without memoization
  startTime: number, // when React began rendering this update
  commitTime: number, // when React committed this update
 interactions: Set<any> // the Set of interactions belonging to this update
) => {
  console.log("Profiler ID:", id);
  console.log("Phase:", phase);
  console.log("Actual Duration:", actualDuration);
  console.log("Base Duration:", baseDuration);
  console.log("Start Time:", startTime);
  console.log("Commit Time:", commitTime);
  console.log("Interactions:", interactions);
};
*/
root.render(
  <React.StrictMode>
    {/* <Profiler id="App" onRender={onRenderCallback}> */}
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <ThemeProvider>
          <HelmetProvider>
            <BrowserRouter>
              <Suspense fallback={<Loader />}>
                <ErrorBoundary>
                  <SnackbarProvider maxSnack={5} autoHideDuration={5000}>
                    <App />
                  </SnackbarProvider>
                </ErrorBoundary>
              </Suspense>
            </BrowserRouter>
          </HelmetProvider>
        </ThemeProvider>
      </AuthProvider>
      <ReactQueryDevtools
        initialIsOpen={false}
        position="top"
        buttonPosition="top-left"
      />
    </QueryClientProvider>
    {/* </Profiler> */}
  </React.StrictMode>
);
