export const api = {
  getAccessToken: (): string => {
    return window.localStorage.getItem("accessToken") || "";
  },
  setAccessToken: (accessToken: string): void => {
    window.localStorage.setItem("accessToken", accessToken);
  },
  clearAccessToken: (): void => {
    window.localStorage.removeItem("accessToken");
  },
};
