import { Navigate } from "react-router-dom";
import useAuth from "src/hooks/useAuth";

interface RoleGuardProps {
  children: React.ReactNode;
  allowedRoles: string[];
}

const RoleGuard: React.FC<RoleGuardProps> = (props) => {
  const { user } = useAuth();
  const { allowedRoles, children } = props;

  const hasAllowedRole = allowedRoles.some(
    (role: string) => user?.role.includes(role)
  );

  if (!hasAllowedRole && user) {
    return <Navigate to="*" />;
  }

  return <>{children}</>;
};

export default RoleGuard;
