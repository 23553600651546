import React from "react";
import { Navigate } from "react-router-dom";
import Loader from "../Loader";
import useAuth from "src/hooks/useAuth";

interface GuestGuardProps {
  children: React.ReactNode;
}

const GuestGuard: React.FC<GuestGuardProps> = ({ children }) => {
  const { isAuthenticated, isInitialized } = useAuth();

  if (!isInitialized) {
    return <Loader />; // Show loading while initializing
  }

  if (isAuthenticated) {
    return <Navigate to="/shift-plans" replace />;
  }

  // Render children components if not authenticated
  return children;
};

export default GuestGuard;
