import { iconNavbar } from "src/utils/loadSvg";

interface NavItem {
  title: string;
  path: string;
  icon: JSX.Element;
}

export const navConfig: NavItem[] = [
  {
    title: "Plány zmien",
    path: "/shift-plans",
    icon: iconNavbar("calendar"),
  },
  {
    title: "Oddelenia",
    path: "/departments",
    icon: iconNavbar("group"),
  },
];
