import { Outlet, Navigate, RouteObject } from "react-router-dom";
import DashboardLayout from "src/layouts/dashboard";
import {
  Login,
  Page404,
  ShiftPlanDetail,
  Departments,
  DepartmentDetail,
  ShiftPlan,
} from "./sections";
import GuestGuard from "src/components/guards/GuestGuard";
import RoleGuard from "src/components/guards/RoleGuard";
import { Role } from "src/types/user";
import AuthGuard from "src/components/guards/AuthGuard";

// Route configuration with proper typing
const routes: RouteObject[] = [
  {
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Outlet />
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      {
        path: "departments",
        children: [
          {
            index: true,
            element: (
              <RoleGuard
                allowedRoles={[
                  Role.SUPER_ADMIN,
                  Role.ORGANIZATION_ADMIN,
                  Role.DEPARTMENT_MANAGER,
                  Role.WORKER,
                ]}
              >
                <Departments />
              </RoleGuard>
            ),
          },
          {
            path: ":id",
            element: (
              <RoleGuard
                allowedRoles={[
                  Role.SUPER_ADMIN,
                  Role.ORGANIZATION_ADMIN,
                  Role.DEPARTMENT_MANAGER,
                  Role.WORKER,
                ]}
              >
                <DepartmentDetail />
              </RoleGuard>
            ),
          },
        ],
      },
      {
        path: "shift-plans",
        children: [
          {
            index: true,
            element: (
              <RoleGuard
                allowedRoles={[
                  Role.SUPER_ADMIN,
                  Role.ORGANIZATION_ADMIN,
                  Role.DEPARTMENT_MANAGER,
                  Role.WORKER,
                ]}
              >
                <ShiftPlan />
              </RoleGuard>
            ),
          },
          {
            path: ":id",
            element: (
              <RoleGuard
                allowedRoles={[
                  Role.SUPER_ADMIN,
                  Role.ORGANIZATION_ADMIN,
                  Role.DEPARTMENT_MANAGER,
                  Role.WORKER,
                ]}
              >
                <ShiftPlanDetail />
              </RoleGuard>
            ),
          },
        ],
      },
    ],
  },
  {
    path: "login",
    element: (
      <GuestGuard>
        <Login />
      </GuestGuard>
    ),
  },
  {
    path: "404",
    element: <Page404 />,
  },
  {
    path: "*",
    element: <Navigate to="/shift-plans" replace />,
  },
];

export default routes;
