// AuthGuard.jsx

import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import Loader from "../Loader";
import useAuth from "src/hooks/useAuth";

interface AuthGuardProps {
  children: React.ReactNode;
}

const AuthGuard: React.FC<AuthGuardProps> = ({ children }) => {
  const { isAuthenticated, isInitialized } = useAuth();
  const location = useLocation();

  if (!isInitialized) {
    return <Loader />;
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  // Render children components if authenticated
  return children;
};

export default AuthGuard;
