import { useEffect, useState } from 'react';
import Logo from '../../assets/logo.png';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Drawer from '@mui/material/Drawer';
import Avatar from '@mui/material/Avatar';
import { alpha, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import ListItemButton from '@mui/material/ListItemButton';
import useMediaQuery from '@mui/material/useMediaQuery';

import { usePathname } from 'src/routes/hooks';
import { RouterLink } from 'src/routes/components';
import Scrollbar from 'src/components/scrollbar';
import { navConfig } from './config-navigation';
import useAuth from 'src/hooks/useAuth';
import { Button } from '@mui/material';
import { primary } from 'src/theme/palette';

const COLLAPSED_WIDTH = 72;
const EXPANDED_WIDTH = 240;

export default function Nav({ openNav, onCloseNav }) {
  const {user, logout} = useAuth();
  const pathname = usePathname();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const isShiftPlanPage = pathname.includes('shift-plans/');
  const [isCollapsed, setIsCollapsed] = useState(isShiftPlanPage);
  
  useEffect(() => {
    setIsCollapsed(pathname.includes('shift-plans/'));
  }, [pathname]);

  const navWidth = isCollapsed ? COLLAPSED_WIDTH : EXPANDED_WIDTH;

  const renderAccount = (isMobile) => (
    <Box
      sx={{
        position: 'fixed',
        bottom: 0,
        width: isMobile ? EXPANDED_WIDTH : navWidth,
      }}
    >
      <Box
        sx={{
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        {!isCollapsed && <Button 
          variant="outlined"
          fullWidth
          sx={{ border: 'none', color: primary.contrastText  }}
          onClick={logout}
        >
          Odhlásiť sa
        </Button>}

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: isCollapsed && !isMobile ? 'center' : 'flex-start',
          }}
        >
          <Avatar 
            src={user.avatarUrl} 
            alt="photoURL"
            sx={{ 
              width: 40,
              height: 40
            }} 
          />
          {(!isCollapsed || isMobile) && (
            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle2">
                {user.firstName} {user.lastName}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );

  const renderLogo = () => (
    <Box 
      sx={{ 
        p: 2.5,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        component="img"
        src={Logo}
        sx={{ 
          height: 28,
          width: 'auto',
          objectFit: 'contain'
        }}
        alt="Planly logo"
      />
    </Box>
  );

  const renderMenu = (isMobile) => (
    <Stack 
      component="nav" 
      spacing={1} 
      sx={{ 
        px: 1,
        width: '100%',
        mb: 8,
      }}
    >
      {navConfig.map((item) => (
        <NavItem 
          key={item.title} 
          item={item} 
          isCollapsed={!isMobile && isCollapsed}
          isMobile={isMobile}
        />
      ))}
    </Stack>
  );

  const renderContent = (isMobile) => (
    <Scrollbar
      sx={{
        height: '100%',
        bgcolor: (theme) => alpha(theme.palette.grey[500], 0.2),
        '& .simplebar-content': {
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      {renderLogo(isMobile)}
      {renderMenu(isMobile)}
      {renderAccount(isMobile)}
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: 0,
        width: {
          lg: navWidth
        },
      }}
    >
      {isDesktop ? (
        <Box
          sx={{
            height: '100vh',
            position: 'fixed',
            width: navWidth,
            transition: theme => theme.transitions.create('width', {
              duration: theme.transitions.duration.standard,
            }),
            bgcolor: 'background.paper',
            borderRight: '1px solid',
            borderColor: 'divider',
            overflow: 'hidden',
          }}
        >
          {renderContent(false)}
        </Box>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: {
              width: EXPANDED_WIDTH,
              bgcolor: 'background.default',
            },
          }}
        >
          {renderContent(true)}
        </Drawer>
      )}
    </Box>
  );
}

function NavItem({ item, isCollapsed, isMobile }) {
  const pathname = usePathname();
  const active = item.path === pathname;

  return (
    <ListItemButton
      component={RouterLink}
      href={item.path}
      sx={{
        height: 56,
        width: isCollapsed ? 56 : '100%',
        borderRadius: 1,
        typography: 'body2',
        color: 'text.secondary',
        textTransform: 'capitalize',
        fontWeight: 'fontWeightMedium',
        display: 'flex',
        alignItems: 'center',
        justifyContent: isCollapsed ? 'center' : 'flex-start',
        ...(active && {
          color: 'primary.main',
          fontWeight: 'fontWeightSemiBold',
          bgcolor: (theme) => alpha(theme.palette.primary.main, 0.08),
          '&:hover': {
            bgcolor: (theme) => alpha(theme.palette.primary.main, 0.16),
          },
        }),
        ...(isMobile && {
          px: 2,
          '&:hover': {
            bgcolor: (theme) => alpha(theme.palette.primary.main, 0.08),
          },
        }),
        ...(!isCollapsed && {
          px: 2,
        }),
      }}
    >
      <Box 
        component="span" 
        sx={{ 
          width: 32,
          height: 32,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: active ? 'primary.main' : 'inherit',
          '& svg': {
            width: 24,
            height: 24,
          },
        }}
      >
        {item.icon}
      </Box>

      {(!isCollapsed || isMobile) && (
        <Box 
          component="span"
          sx={{
            ml: 2,
            fontSize: 14,
            flexGrow: 1,
          }}
        >
          {item.title}
        </Box>
      )}
    </ListItemButton>
  );
}