import React from "react";
import { LinearProgress, Box, Typography } from "@mui/material";

interface LoaderProps {
  text?: string;
}

const Loader: React.FC<LoaderProps> = ({ text }) => {
  return (
    <Box
      sx={{
        minHeight: "80vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: 3,
      }}
    >
      <Box
        sx={{
          width: {
            xs: "90%", // width on extra-small screens
            sm: "70%", // width on small screens
            md: "50%", // width on medium and larger screens
          },
          maxWidth: "400px",
        }}
      >
        <LinearProgress
          sx={{
            height: 6,
            borderRadius: 3,
            "& .MuiLinearProgress-bar": {
              borderRadius: 3,
            },
          }}
        />
      </Box>

      {text && (
        <Typography
          variant="body1"
          sx={{
            textAlign: "center",
            color: "text.secondary",
            mt: 2,
            fontWeight: 500,
          }}
        >
          {text}
        </Typography>
      )}
    </Box>
  );
};

export default Loader;
