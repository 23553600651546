import { useRoutes } from "react-router-dom";
import routes from "./routes/index.tsx";
import "./styles/global.module.css"

// ----------------------------------------------------------------------

export default function App() {
  const content = useRoutes(routes);

  return (
    <div>
      {content}
    </div>
  );
}
