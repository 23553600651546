import { SxProps } from "@mui/material";
import SvgColor from "src/components/svg-color/svg-color";

export const iconNavbar = (name: string): JSX.Element => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 } as SxProps}
  />
);

export const icon = (
  name: string,
  width: number,
  height: number,
  color?: string
): JSX.Element => (
  <SvgColor
    src={`/assets/icons/${name}.svg`}
    sx={{ width: width, height: height, color: color } as SxProps}
  />
);
